<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
          >首页
          </el-breadcrumb-item
          >
          <el-breadcrumb-item>商家联盟</el-breadcrumb-item>
          <el-breadcrumb-item>商家列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="admin_main_block admin_m15">
        <div class="admin_main_block_left">
          <el-form>
            <el-form-item label="商户渠道">
              <el-select v-model="where.appid" placeholder="商户渠道">
                <el-option label="全部" value=""></el-option>
                <el-option label="惠链接" value="hlj"></el-option>
                <el-option label="有免惠" value="ymh"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商家名称">
              <el-input v-model="where.companyName" placeholder="商家名称"></el-input>
            </el-form-item>
            <el-form-item label="商家手机号">
              <el-input v-model="where.mobileNo" placeholder="商家手机号"></el-input>
            </el-form-item>
            <el-form-item label="商家类型">
              <el-select v-model="where.merchantType" placeholder="商家类型">
                <el-option label="全部" value=""></el-option>
                <el-option label="个体户" value="1"></el-option>
                <el-option label="企业商户" value="2"></el-option>
                <el-option label="小微" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="加入日期">
              <date-picker @get-date="getDate"></date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" plain icon="el-icon-search" @click="getGoodsList()"
              >条件筛选
              </el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                  type="primary"
                  plain
                  icon="el-icon-search"
                  @click="reset()"
              >重置
              </el-button
              >
            </el-form-item>
          </el-form>
        </div>

        <div class="admin_main_block_right">
          <div>
            <el-button type="primary" icon="el-icon-tickets"
            >导出数据
            </el-button
            >
          </div>
        </div>
      </div>
      <div class="admin_table_main">
        <el-table :data="list"
                  @selection-change="handleSelectionChange"
                  v-loading="loading"
                  element-loading-text="拼命加载中"
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(0, 0, 0, 0.1)"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column prop="id" label="商家ID" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="appid" label="商户渠道">
            <template slot-scope="scope">
              <el-tag :type="shopChannel(scope.row.appid,1)" v-if="scope.row.appid">
                {{ shopChannel(scope.row.appid,0) }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="legaler" label="商家姓名"></el-table-column>
          <el-table-column prop="companyName" label="商家名称"></el-table-column>
          <el-table-column prop="tmerchantNo" label="渠道商户号"></el-table-column>
          <el-table-column prop="mobileNo" label="商家手机号"></el-table-column>
          <!--          <el-table-column prop="legaler" label="申请人姓名"></el-table-column>-->
          <el-table-column prop="merchantType" label="类型">
            <template slot-scope="scope">
              {{ merchantType[scope.row.merchantType] }}
            </template>
          </el-table-column>
          <!--          <el-table-column prop="payChannel" label="支付通道"></el-table-column>-->
          <!--          <el-table-column prop="channelRate" label="通道汇率"></el-table-column>-->
          <el-table-column prop="applyDate" label="加入时间" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="state" label="状态">
            <template slot-scope="scope">
<!--              <el-tag type>{{ scope.row.stateText }}</el-tag>-->
              <el-tag type="success" v-if="scope.row.state == 1">
                开启
              </el-tag>
              <el-tag type="danger" v-if="scope.row.state == 0">
                关闭
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="signFlag" label="签约状态">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.signFlag == 1">{{
                  scope.row.signFlagText
                }}
              </el-tag>
              <el-tag type="warning" v-else-if="scope.row.signFlag == 2">{{
                  scope.row.signFlagText
                }}
              </el-tag>
              <el-tag type="danger" v-else-if="scope.row.signFlag == 0">{{
                  scope.row.signFlagText
                }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="120px">
            <template slot-scope="scope">
              <el-dropdown>
                <span class="el-dropdown-link">
                  操作<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="el-icon-s-shop"
                  >商家信息
                  </el-dropdown-item
                  >
                  <el-dropdown-item icon="el-icon-edit">
                    <router-link
                        :to="{
                        path: '/Admin/merchant/merchant/merchantUpdate',
                        query: {
                          id: scope.row.id,
                        },
                      }"
                    >
                      修改商家信息
                    </router-link>
                  </el-dropdown-item>
                  <el-dropdown-item icon="el-icon-notebook-2">
                    <router-link
                        :to="{
                        path: '/Admin/merchant/shop/shoplist',
                        query: {
                          id: scope.row.id,
                        },
                      }"
                    >
                      门店列表
                    </router-link>
                  </el-dropdown-item>
                  <el-dropdown-item disabled icon="el-icon-setting"
                  >通道配置
                  </el-dropdown-item
                  >
                  <el-dropdown-item disabled icon="el-icon-lock"
                  >重置密码
                  </el-dropdown-item
                  >
                  <el-dropdown-item disabled icon="el-icon-user"
                  >客户端登陆
                  </el-dropdown-item
                  >
                  <el-dropdown-item v-if="scope.row.appid === 'ymh'">
                    <el-button
                            type="text"
                            class="el-icon-s-promotion"
                            size="medium"
                            @click="sendSMS(scope.row.id)"
                    >进件短信
                    </el-button>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.appid === 'hlj'">
                    <el-button
                            type="text"
                            class="el-icon-s-promotion"
                            size="medium"
                            @click="syncCheckResultHlj(scope.row.id)"
                    >同步惠链接
                    </el-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div class="admin_table_main_pagination">
          <el-pagination
              @current-change="currentChange"
              @size-change="handleSizeChange"
              background
              :total="totalData"
              :page-size="pageSize"
              :current-page="currentPage"
              :page-sizes="[10, 20, 50, 100]"
              layout="sizes, prev, pager, next, total, jumper"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import datePicker from "@/components/admin/date-picker.vue";
import {shopChannel} from "@/plugins/map.js";
export default {
  components: {
    datePicker,
  },
  data() {
    return {
      shopChannel,
      list: [],
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      selectId: "",
      loading: false,
      merchantType: {
        1: "个体户",
        2: "企业商户",
        3: "小微",
      },
      where: {
        // applyDateEnd: "",
        // applyDateStart: "",
        // companyName: "",
        // merchantType: "",
        page: 1,
        pageSize: 0,
      },
    };
  },
  methods: {
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v.id);
      });
      this.selectId = ids.join(",");
    },
    getGoodsList() {
      this.loading = true;
      this.where.pageSize = this.pageSize;
      this.$post(this.$api.merchantList, this.where).then((res) => {
        this.where.page = res.data.current;
        this.totalData = res.data.total;
        this.list = res.data.records;
        this.loading = false;
      });
    },
    reset(){
      this.where = {
        pageNum: 0,
        page: 1,
        pageSize: 0,
      };
      this.getGoodsList();
    },
    getDate(date) {
      console.log(date)
      this.where.applyDateStart = date[0];
      this.where.applyDateEnd = date[1];
    },
    currentChange(e) {
      this.currentPage = e;
      this.getGoodsList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getGoodsList();
    },
    syncCheckResultHlj(id){
      this.$get(this.$api.merchantSyncCheckResultHlj + id, {}).then((res) => {
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: "发送成功",
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    sendSMS(id) {
      this.$get(this.$api.sendMerchantApprovedNotify + id, {}).then((res) => {
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: "发送成功",
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    }
  },
  created() {
    this.getGoodsList();
  },
};
</script>
